var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form'),_c('section',[_c('article',{staticStyle:{"height":"295px","width":"600px"}},[_c('field',{staticStyle:{"top":"10px","right":"180px"},attrs:{"name":"activo","widget":"toggle","labels":{ checked: 'Activo', unchecked: 'Inactivo' },"width":75,"inline-label":"","searchable":""}}),_c('button',{staticClass:"button",staticStyle:{"position":"absolute","top":"10px","right":"10px"},attrs:{"disabled":_vm.mode != 'edit' && _vm.mode != 'new'},on:{"click":_vm.abrirSelector}},[_vm._v("Selector de artículos")]),_c('field',{staticStyle:{"width":"60px","height":"60px","top":"10px","left":"10px"},attrs:{"name":"imagen","widget":"m2o_img","dbAdapter":"imagen_articulo","label":"Imagen","inputStyle":"top:100%;left:0px;width:100%","closeButtonStyle":"left: calc(100% - 15px);top:0;color:#666;cursor:pointer;"}}),_c('field',{staticStyle:{"top":"15px","left":"85px"},attrs:{"name":"codigo","widget":"char","searchable":"","label":"Código","help":"Código","placeholder":"Cod","width":"50px","readonly":_vm.mode == 'edit'}}),_c('field',{staticStyle:{"top":"56px","left":"85px"},attrs:{"name":"nombre","widget":"char","searchable":"","label":"Nombre","help":"Descripción","placeholder":"Descripción","width":"400px"}}),_c('field',{ref:"articulos",staticStyle:{"top":"100px","left":"10px"},attrs:{"name":"articulos","widget":"handsontable","label":"Artículos","primary":"codigo","height":183,"width":400,"minRows":7,"fields":[{ name: 'thumbnail', fields: ['imagen'] }],"columns":[
        {
          name: 'ean13',
          header: '|||||||||',
        },
        {
          name: 'codigo',
          header: 'Código',
        },
        {
          name: 'nombre',
          header: 'Descripción',
          readOnly: true,
        },
        {
          name: 'familia_id',
          header: 'Familia',
          type: 'm2o',
          primary: 'codigo',
          label: 'nombre',
          showCode: true,
          readOnly: true,
        },
        {
          name: 'seccion_id',
          header: 'Sección',
          type: 'm2o',
          primary: 'codigo',
          label: 'nombre',
          showCode: true,
          readOnly: true,
        } ]}}),(_vm.selectedArticulo.thumbnail)?_c('img',{staticStyle:{"width":"180px","height":"180px","position":"absolute","top":"100px","right":"5px"},attrs:{"src":_vm.selectedArticulo.thumbnail.imagen}}):_vm._e()],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_c('div',{staticClass:"item-title"},[_vm._v("Listado")]),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }